'use strict'
document.addEventListener('DOMContentLoaded', function() {
  // Locale button click
  var btnLocale = document.querySelectorAll(".btn-locale");
  btnLocale.forEach(function(target) {
    target.addEventListener('click', function(e) {
      setLocale(e.currentTarget)
    })
  })

  var languageTypes = document.querySelectorAll('.language-type')
  languageTypes.forEach(function(target) {
    target.addEventListener('click', function(e) {
      setLocale(e.currentTarget)
    })
  })


  var param
  var languageType
  if(storageAvailable('sessionStorage')) {
    languageType = sessionStorage.getItem('language_type')
  }

  param = GetQueryString();
  if(param) {
    if(param["locale"]) {
      languageType = param["locale"]
    }

    var target_id = "option_" + languageType;
    var locale = document.getElementById(target_id)
    locale.checked = true
  }

  // ToucEvent
  let target = document.getElementById("footer_navbar")
  let navbarCollapse = document.getElementById("navbarCollapse")

  let startX
  let startY
  let moveX
  let moveY
  let dist = 30
  let distY = 60
  if(target) {
    target.addEventListener('touchstart', function(e) {
      startX = e.touches[0].pageX
      startY = e.touches[0].pageY
    })
    target.addEventListener('touchmove', function(e) {
      e.preventDefault()
      moveX = e.changedTouches[0].pageX
      moveY = e.changedTouches[0].pageY
    })
    target.addEventListener('touchend', function(e) {
      var index
      if(moveX == undefined || moveX == 0){
        // スワイプなし時の処理
      }
      else if (startX > moveX && startX > moveX + dist) {
        // 右から左にスワイプした時の処理
        if (startY < moveY && startY + distY < moveY) {
          // 上から下にスワイプした時の処理
          if(navbarCollapse){
            if (navbarCollapse.classList.contains("show")) {
              navbarCollapse.classList.remove("show")
            }
          }
        }
      }
      else if (startX < moveX && startX + dist < moveX) {
        // 左から右にスワイプした時の処理
        // 下から上にスワイプした時の処理
        if (startY > moveY) {
          if(navbarCollapse){
            if (!navbarCollapse.classList.contains("show")) {
              navbarCollapse.classList.add("show")
            }
          }
        }
      }
      else if (startY < moveY && startY + distY < moveY) {
        // 上から下にスワイプした時の処理
        if(navbarCollapse){
          if (navbarCollapse.classList.contains("show")) {
            navbarCollapse.classList.remove("show")
          }
        }
      }
      else {
      }
      startX = 0
      startY = 0
      moveX = 0
      moveY = 0
    })
  }
})
function storageAvailable(type) {
  var storage;
  try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
  }
  catch(e) {
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          (storage && storage.length !== 0);
  }
}
function setLocale(ele) {
  var locale = ele.dataset.locale
  if(storageAvailable('sessionStorage')) {
    sessionStorage.setItem('language_type', locale)
  }
  location.href = location.origin + "?locale=" + locale
}
function GetQueryString() {
  if (1 < document.location.search.length) {
    var query = document.location.search.substring(1);
    var parameters = query.split('&');
    var result = new Object();
    for (var i = 0; i < parameters.length; i++) {
      var element = parameters[i].split('=');
      var paramName = decodeURIComponent(element[0]);
      var paramValue = decodeURIComponent(element[1]);
      result[paramName] = decodeURIComponent(paramValue);
    }
    return result;
  }
  return null;
}